
import React from 'react';
import deleteImage from '../images/delete-account.png';
import deleteImage2 from '../images/delete-account2.png';

const AccountDeletionPage = () => {
  return (
    <div style={containerStyle}>
      <h1 style={headerStyle}>Account Deletion Request</h1>
      <p style={instructionStyle}>
        If you wish to delete your account and associated data from our app, please follow the steps below:
      </p>

      <ol style={stepsListStyle}>
        <li>
          <strong>Open the App:</strong> Find and open the app on your mobile device.
        </li>
        <li>
          <strong>Go to Settings:</strong> Navigate to the app settings within the app.
        </li>
        <li>
          <strong>Find Delete Account:</strong> Look for the option related to account deletion or removal.
          <div style={images}>
          <img src={deleteImage} alt="Find Account Deletion" style={imageStyle} />
          <img src={deleteImage2} alt="Find Account Deletion" style={imageStyle} />
          </div>
        </li>
        <li>
          <strong>Submit Request:</strong> Follow the on-screen instructions to submit your account deletion request.
        </li>
      </ol>
    </div>
  );
};

// Styles
const containerStyle = {
  maxWidth: '600px',
  margin: 'auto',
  padding: '20px',
};

const headerStyle = {
  fontSize: '24px',
  marginBottom: '15px',
};

const instructionStyle = {
  marginBottom: '15px',
};

const stepsListStyle = {
  listStyleType: 'decimal',
  paddingLeft: '20px',
  marginBottom: '20px',
};
const images = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
};
const imageStyle = {
  maxWidth: '50%',
  marginTop: '20px',
  marginLeft: '25%',
  marginBottom: '20px'
};

export default AccountDeletionPage;